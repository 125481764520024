import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { numericItemLastIndex } from "utils/numericItem";

import { getPayments, getPaymentById } from "domain/actions/payments";

import Card from "./card";
import { SpinnerWrapper } from "components/wrappers";

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function DataView(props) {
  const { payments, count, sort } = props;
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState(payments.slice());

  const fetchMoreData = () => {
    if (payments.length < 10) {
      setHasMore(false);
      return;
    }
    const last = payments[payments.length - 1];
    if (last) props.getPayments(last);
  };

  useEffect(() => {
    const _payments = numericItemLastIndex(
      payments,
      items.length,
      count,
      sort?.createdAt
    );
    const array = items.concat(_payments.slice());

    const uniqueArray = array.filter(onlyUnique);
    setItems(uniqueArray);
  }, [payments]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEdit = (item) => {
    props.getPaymentById(item.id);
  };

  const refresh = () => {
    setItems([]);
    setHasMore(true);
    props.getPayments({ id: null });
  };

  useEffect(() => {
    if (props.refresh) refresh();
  }, [props.refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className={props.className}>
        <SpinnerWrapper className="c-pagination-table" position="center">
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<></>}
          >
            {items.map((i, index) => {
              return <Card key={index} item={i} onPreview={() => onEdit(i)} />;
            })}
          </InfiniteScroll>
        </SpinnerWrapper>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  payments: state.payments.payments,
  refresh: state.payments.refresh,
  showFilters: state.paymentsFilter.show,
  count: state.paymentsFilter.count,
  sort: state.paymentsFilter.sort,
});

export default connect(mapStateToProps, { getPayments, getPaymentById })(
  DataView
);
