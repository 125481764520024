import {
  SET_ORGANIZATIONS,
  UPDATE_ORGANIZATIONS,
  // SELECT_ORGANIZATIONS,
  INFO_ORGANIZATION,
  BREAK_ORGANIZATIONS,
  SHOW_WINDOW__ORGANIZATIONS,
  REFRESH_ORGANIZATION,
  SET_WORKERS_ORGANIZATION,
  SET_WORKERS_SELECTED_ORGANIZATION,
} from "domain/types/organizations";

const initialState = {
  organizations: [],
  organizationById: null,
  select: null,
  show: null,
  break: false,
  refresh: false,
  workers: [],
  selectedWorker: null,
};

export default function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
        break: true,
      };
    case UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case INFO_ORGANIZATION:
      return {
        ...state,
        organizationById: action.payload,
      };
    case SET_WORKERS_ORGANIZATION:
      return {
        ...state,
        workers: action.payload,
      };
    case SET_WORKERS_SELECTED_ORGANIZATION:
      return {
        ...state,
        selectedWorker: action.payload,
      };
    case BREAK_ORGANIZATIONS:
      return {
        ...state,
        break: action.payload,
      };
    case SHOW_WINDOW__ORGANIZATIONS:
      return {
        ...state,
        show: action.payload,
      };
    case REFRESH_ORGANIZATION:
      return {
        ...state,
        refresh: !state.refresh,
      };
    default:
      return state;
  }
}
