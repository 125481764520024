import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { setSortsPayments } from "domain/actions/payments/filters";
import { Sorts } from "components/static";
import SortComponent from "components/static/sorts/components/Sort.component";

function SortsPanels({ setSortsPayments, className }) {
  const [date, toggleDate] = useState(null);
  const [sum, toggleSum] = useState(null);
  const [pageLoad, togglePageLoad] = useState(false);

  useEffect(() => {
    let params = {
      createdAt: date ? "ASC" : "DESC",
      sumDoc: sum ? "ASC" : "DESC",
    };
    if (date === null) delete params.createdAt;
    if (sum === null) delete params.sumDoc;

    setSortsPayments(params);
  }, [date, sum, setSortsPayments]);

  useEffect(() => {
    if (!pageLoad) toggleDate(false);
  }, [pageLoad, toggleDate]);

  useEffect(() => {
    togglePageLoad(true);
  }, [togglePageLoad]);

  return (
    <Sorts className={className}>
      <div className="p-d-flex">
        <SortComponent
          label="Дата"
          value={date}
          onChange={(value) => {
            toggleSum(null);
            toggleDate(value === null ? true : value);
          }}
        />
        <SortComponent
          label="Сумма"
          value={sum}
          onChange={(value) => {
            toggleDate(null);
            toggleSum(value === null ? true : value);
          }}
        />
      </div>
    </Sorts>
  );
}

export default connect(null, { setSortsPayments })(SortsPanels);
