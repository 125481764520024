import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SpinnerWrapper } from "components/wrappers";
import EmptyComponent from "../empty";
import PieChart from "./chart";
import Info from "./info";

function ReceiptTypePayments(props) {
  const [isEmpty, setEmpty] = useState(true);

  useEffect(() => {
    const data = props.data;
    if (data && data.length > 0) {
      const sum = +data[0].total;
      setEmpty(sum === 0);
    }
  }, [setEmpty, props.data]);

  const renderContent = (
    <>
      {!isEmpty ? (
        <>
          <div className="c-h-300 p-col-12 p-p-1">
            <PieChart item={true} data={props.data} />
          </div>
          <div className="p-col-12 p-p-1 p-d-flex p-flex-column p-jc-center">
            <Info item={true} data={props.data} />
          </div>
        </>
      ) : (
        <EmptyComponent className="p-col-12 p-text-center" />
      )}
    </>
  );

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-3 c-box-shadow">
        <div className="analytics-title">Тип оплаты</div>

        <SpinnerWrapper position="center" progress={props.progress}>
          <div
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            style={{ minHeight: "390px", alignContent: "baseline" }}
          >
            {!props.progress && renderContent}
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data?.paymentType,
  progress: state.analytics.isProgress,
});

export default connect(mapStateToProps)(ReceiptTypePayments);
