import {
  SET_ORGANIZATIONS,
  UPDATE_ORGANIZATIONS,
  SELECT_ORGANIZATIONS,
  SET_PAGINATION_ORGANIZATION,
  SHOW_WINDOW__ORGANIZATIONS,
  SET_PROPS_CREATE_ORGANIZATION,
  SET_PROPS_EDIT_ORGANIZATION,
  SET_WORKERS_ORGANIZATION,
  SET_WORKERS_SELECTED_ORGANIZATION,
} from "domain/types/organizations";

import { TOGGLE_PROGRESS, SHOW_ERROR, SHOW_SUCCESS } from "domain/actionTypes";
import getFilters from "controllers/getFilters";
import { sleep } from "controllers/sleep";
import { DadataController, ApiController } from "domain/controllers";
import { isINN } from "utils/rus.tax.codes.util";
import orderBy from "lodash/orderBy";

const Dadata = new DadataController();
const Api = new ApiController();

export function postConnect(data) {
  return async function thunk(dispatch, getState) {
    const organizationId = getState().orgCreate.select.id;

    const _data = {
      defaultOrgAcqId: data.acqType?.id ? data.acqType?.id : null,
      defaultCashboxId: data.cashbox?.id ? data.cashbox?.id : null,
      defaultPaymentSum: data.defaultPaymentSum,
      name: data.name,
      paymentTitle: data.title,
      textColor: data.color ? `#${data.color}` : `#fff`,
    };
    return await Api.post(`/accept/new/${organizationId}`, _data);
  };
}

export function putConnect(data) {
  return async function thunk(dispatch, getState) {
    const _data = {
      name: data.name,
      bindParams: {
        defaultOrgAcqId: data.acqType?.id ? data.acqType?.id : null,
        defaultCashboxId: data.cashbox?.id ? data.cashbox?.id : null,
        defaultPaymentSum: data.defaultPaymentSum,
        paymentTitle: data.title,
        textColor: `#${data.color}`,
        logo: data.logo,
        background: data.background,
      },
    };

    return await Api.put(`/accept/${data.acceptId}`, _data);
  };
}

export function regenerationToken(acceptId) {
  return async function thunk(dispatch, getState) {
    return await Api.post(`/accept/${acceptId}/regenerate`, {});
  };
}

export function getConnectFile(id) {
  return async function thunk(dispatch, getState) {
    const connectId = id;

    const response = await Api.get(`/accept/${connectId}/file`);

    return response.data;
  };
}

export function postConnectFiles(data) {
  return async function thunk(dispatch, getState) {
    const connectId = data.id;
    const fd = new FormData();

    if (data.primary)
      fd.append("primary", data.primary, `primary_${new Date().getTime()}`);
    if (data.background)
      fd.append(
        "background",
        data.background,
        `background_${new Date().getTime()}`
      );

    await Api.post(`/accept/${connectId}/file`, fd);
  };
}

export function getOrganizations(page) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    await sleep(300);

    let params = { limit: 15, page: page ? page : 1 };
    let filters = getFilters(getState, "orgFilters");

    Object.assign(params, filters);

    return await Api.post(`/org/list`, params)
      .then((response) => {
        let result = response.data.result;
        dispatch({
          type: SET_ORGANIZATIONS,
          payload: result.data,
        });

        dispatch({
          type: SET_PAGINATION_ORGANIZATION,
          payload: result,
        });

        dispatch({ type: TOGGLE_PROGRESS, payload: false });
        return response;
      })
      .catch((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

export function addQuick(params) {
  return async function thunk(dispatch, getState) {
    const selectOrg = getState().orgCreate.select;
    if (!selectOrg) console.error("организация не выбрана");

    return await Api.post(`/org/addQuick/${selectOrg.id}`, params)
      .then((response) => {
        console.log(response);
        selectOrg.quickReceiptParams =
          response?.data?.result?.quickReceiptParams;
        dispatch({ type: SET_PROPS_EDIT_ORGANIZATION, payload: selectOrg });

        dispatch({ type: SHOW_SUCCESS, payload: "Конфигурация сохранена" });
      })
      .catch((e) => console.error(e));
  };
}

export function getLazyOrganizations(page, limit = 10) {
  return async function thunk(dispatch, getState) {
    let params = { limit: limit, page: page };
    let filters = getFilters(getState, "orgFilters");

    Object.assign(params, filters);

    return await Api.post(`/org/list`, params)
      .then((response) => response)
      .catch((e) => console.error(e));
  };
}

export function selectOrg(id) {
  return async function thunk(dispatch, getState) {
    await Api.get(`/org/info/${id}`)
      .then((response) => {
        const result = response.data.result;
        const data = result.organization;
        data.acceptLicenses = result.acceptLicenses
          ? result.acceptLicenses
          : [];

        dispatch({
          type: SELECT_ORGANIZATIONS,
          payload: data,
        });
        dispatch({ type: SHOW_WINDOW__ORGANIZATIONS, payload: true });
      })
      .catch((e) => console.log(e));
  };
}

export function activeVirtualCashbox(id, value) {
  return async function thunk(dispatch, getState) {
    const params = {
      organizationId: id,
      status: Number(!value),
    };

    return await Api.post(`/cashbox/virtual`, params)
      .then((r) => activeThunk(dispatch, getState, params))
      .catch((e) => console.log(e));
  };
}

async function activeThunk(dispatch, getState, params) {
  const list = getState().organizations.organizations.slice();
  const org = list.filter((i) => i.id === params.organizationId)[0];
  const indexOrg = list.indexOf(org);
  const indexVirtual = org.cashbox
    .map((i) => (i.cashboxModel ? i.cashboxModel.model : null))
    .indexOf("Эмулятор");

  if (indexVirtual === -1) return getOrganizationsThunk(dispatch, getState);

  if (indexVirtual >= 0) org.cashbox[indexVirtual].status = params.status;

  list[indexOrg] = org;

  dispatch({ type: UPDATE_ORGANIZATIONS, payload: list });
}

async function getOrganizationsThunk(dispatch, getState) {
  let page = getState().pagination.page;
  let params = { limit: 9999, page: page };
  let filters = getFilters(getState, "orgFilters");
  Object.assign(params, filters);

  await Api.post(`/org/list`, params)
    .then((response) => {
      return dispatch({
        type: SET_ORGANIZATIONS,
        payload: response.data.result.data,
      });
    })
    .catch((e) => console.log(e));
}

export function getOrganizationsByInn(inn) {
  return async function thunk(dispatch, getState) {
    if (!isINN(inn)) return;

    await Dadata.getOrganizationByINN(inn)
      .then((response) => {
        if (!response.suggestions.length) {
          dispatch({
            type: SHOW_ERROR,
            payload: "По данному ИНН организация не найдена",
          });
          return;
        }

        setPropsOrganization(response.suggestions[0], dispatch, getState);
      })
      .catch((e) => console.log(e));
  };
}

function setPropsOrganization(data, dispatch, getState) {
  const organization = data.data;
  const item = { ...getState().orgCreate.item };
  const entities = getState().requisites.entity;

  if (item.legalEntity?.isLegalEntity) {
    const entity = entities.filter(
      (entity) => entity.name === organization.opf.short
    );
    if (entity.length) {
      item.entityId = entity[0].id;
    }

    item.name = organization.name.short;
    item.kpp = organization.kpp;
    item.ogrn = organization.ogrn;

    if (organization.management) {
      const manager = organization.management.name.split(" ");
      item.managerFirstName = manager[1];
      item.managerMiddleName = manager[2];
      item.managerLastName = manager[0];
      item.positionName = organization.management.post;
    }
  } else {
    item.name = organization.name.short_with_opf;
    item.ogrnip = organization.ogrn;

    const manager = organization.fio;
    item.managerFirstName = manager.name;
    item.managerMiddleName = manager.patronymic;
    item.managerLastName = manager.surname;
    item.positionName = "Индивидуальный предприниматель";
  }

  item.addressLegal = organization.address.value;
  item.addressActual = organization.address.value;
  item.region = organization.address.data.region;
  item.postcode = organization.address.data.postal_code;

  dispatch({ type: SET_PROPS_CREATE_ORGANIZATION, payload: item });
}

export function getWorkers() {
  return async function thunk(dispatch, getState) {
    const response = await Api.get(`/worker/list`);

    dispatch({
      type: SET_WORKERS_ORGANIZATION,
      payload: orderBy(
        response.data.result,
        ["isActive", "createdAt"],
        ["desc", "desc"]
      ),
    });
  };
}

export function setSelectedWorker(worker) {
  return async function thunk(dispatch, getState) {
    console.log(worker);
    dispatch({ type: SET_WORKERS_SELECTED_ORGANIZATION, payload: worker });
  };
}

export function switchWorker(cashboxIds, workerId) {
  return async function thunk(dispatch, getState) {
    return await Api.post(`/worker/${workerId}/attach/cashboxes`, {
      cashboxes: cashboxIds,
    });
  };
}
