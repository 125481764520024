import React from "react";
import { connect } from "react-redux";
import { onShowFiltersPayments } from "domain/actions/payments/filters";

import { Heading } from "components/static";

import Settings from "./settingsPanel";
import DataView from "./data.view";
import Menu from "./menu";
import { FiltersIcon } from "components/static";
import PaymentWindow from "../windows/payment";
import { PayLinkWindow } from "routes/pages/receipts/windows";

function Layouts(props) {
  return (
    <div>
      <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
        <div className="p-col-12 p-md-2 p-d-flex p-flex-wrap">
          <div className="p-col-6 p-md-12 p-p-0 ">
            <Heading className="p-col-12 p-pl-3 p-pt-3" title="Платежи" />
            <Menu
              history={props.history}
              className="p-col-12 p-pl-3 p-pt-0 p-pb-0 p-pr-0"
            />
          </div>

          <div className="p-col-6 p-d-md-none p-d-flex p-jc-end">
            <FiltersIcon
              setActive={props.onShowFiltersReceipts}
              active={props.showFilters}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-10 p-d-none">
          <Settings className="p-p-1 p-p-md-3 p-d-flex p-flex-wrap" />
        </div>
      </div>

      <DataView history={props.history} />

      <PaymentWindow />
      <PayLinkWindow />
    </div>
  );
}

const mapStateToProps = (state) => ({
  showFilters: state.paymentsFilter.show,
});
export default connect(mapStateToProps, { onShowFiltersPayments })(Layouts);
