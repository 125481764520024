import React, { useState, useEffect } from "react";

import EmptyComponent from "../empty";
import Summary from "./summary";
import Chart from "./chart";
import { SpinnerWrapper } from "components/wrappers";

import styles from "../style.module.css";
import { connect } from "react-redux";

function CashRevenue(props) {
  const [dataNull, setDataNull] = useState(true);
  const [isComing, setComing] = useState(true);

  useEffect(() => {
    try {
      if (props.data === null) return;
      const users = props.data.users;
      let emptyData = true;

      if (props.data.users.length) {
        users.forEach((i) => {
          if (i.moneyIn === 0 && i.moneyOut === 0) {
            return;
          } else {
            emptyData = false;
          }
        });
      }
      setDataNull(props.data && emptyData);
    } catch (e) {
      console.error(e);
    }
  }, [props.data]);

  const title = isComing ? "Рейтинг операторов" : "Рейтинг операторов";

  const renderContent = (
    <React.Fragment>
      {dataNull ? (
        <EmptyComponent className="p-col-12 p-text-center" />
      ) : (
        <React.Fragment>
          <Summary className="p-col-12 p-pr-0 p-pl-0" isComing={isComing} />

          <div className="p-col-12 p-pr-0 p-pl-0 p-d-flex p-jc-lg-between">
            <span
              onClick={() => setComing(true)}
              className={`${styles.ratingOperators} ${
                isComing && styles.comingRatActive
              }`}
            >
              По чекам прихода
            </span>
            <span
              onClick={() => setComing(false)}
              className={`${styles.ratingOperators} ${
                !isComing && styles.expendRatActive
              }`}
            >
              По чекам расхода
            </span>
          </div>

          <Chart className="p-col-12 p-pr-0 p-pl-0" isComing={isComing} />
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-3 c-box-shadow">
        <div className="analytics-title">{title}</div>

        <SpinnerWrapper position="center" progress={props.progress}>
          <div
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            style={{ minHeight: "390px", alignContent: "baseline" }}
          >
            {!props.progress && renderContent}
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
  progress: state.analytics.isProgress,
});
export default connect(mapStateToProps)(CashRevenue);
